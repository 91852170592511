import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import React from 'react'
import SelectorStyles from '../styles/SelectorStyles'
import NumberedRow from './Layout/NumberedRow'

export const DemoSelectorContainer = ({ rowNumber, children }) => (
    <SelectorStyles>
        <section className='py-5'>
            <Container>
                <NumberedRow number={rowNumber}>
                    <h2 className='h1 mb-3'>Choose demo version</h2>
                    <p className='mb-4'>
                        Unlock the power of convenience: Introduce your customers to an effortless payment experience
                        with Siru Mobile. No need for cash, cards, or downloads. Experience the ease and fun of Siru
                        Mobile, now available with some country-specific variations.
                    </p>

                    <div className='d-grid gap-2 d-md-block'>{children}</div>
                </NumberedRow>
            </Container>
        </section>
    </SelectorStyles>
)

export const SelectorLink = ({ to, name, disabled = false }) => (
    <div className='d-inline-block bg-light me-0 me-md-3 mb-2 shadow'>
        <Link
            to={to}
            className={`btn btn-outline-dark fs-6 rounded-0 spaced-light px-5 w-100 5 ${disabled && 'disabled'}`}>
            {name}
        </Link>
    </div>
)
