import styled from 'styled-components'

const SelectorStyles = styled.div`
    a {
        &:hover {
            background-color: var(--bs-success);
            color: var(--bs-white);
        }
        &[aria-current='page'],
        &.active {
            background-color: var(--bs-success);
            color: var(--bs-white);
        }

        &.btn-outline-dark.disabled {
            opacity: 0.4;
        }
    }
`

export default SelectorStyles
