import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import SelectorStyles from '../styles/SelectorStyles'
import NumberedRow from './Layout/NumberedRow'
import ReactCountryFlag from 'react-country-flag'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'

/**
 * @param {boolean|Array} enabledCountries If given, only these countries will be enabled. If empty array, all will be disabled.
 * @returns {JSX.Element}
 * @constructor
 */
const CountrySelector = ({ enabledCountries = false }) => {
    const { image } = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "country_select_BG.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 3840
                        height: 500
                        placeholder: BLURRED
                        quality: 80
                        transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                    )
                }
            }
        }
    `)

    const bgImage = getImage(image)

    return (
        <SelectorStyles>
            <section className='bg-light'>
                <Container className='position-relative'>
                    <div className='d-flex'>
                        <StyledDiv className='position-relative pt-3 pb-5 w-100'>
                            <NumberedRow number={2} numberContainerClassName='bg-light'>
                                <h2 className='h1 mb-4'>Choose demo country</h2>

                                <div className='d-grid gap-2 d-md-block'>
                                    <SelectorLink
                                        to='/'
                                        name='Finland'
                                        countryCode='FI'
                                        disabled={enabledCountries && !enabledCountries.includes('FI')}
                                    />
                                    <SelectorLink
                                        to='/paywithsiru/se/'
                                        name='Sweden'
                                        countryCode='SE'
                                        disabled={enabledCountries && !enabledCountries.includes('SE')}
                                    />
                                    <SelectorLink
                                        to='/paywithsiru/gb/'
                                        name='United Kingdom'
                                        countryCode='GB'
                                        disabled={enabledCountries && !enabledCountries.includes('GB')}
                                    />
                                    <SelectorLink
                                        to='/paywithsiru/de/'
                                        name='Germany'
                                        countryCode='DE'
                                        disabled={enabledCountries && !enabledCountries.includes('DE')}
                                    />
                                    <SelectorLink
                                        to='/paywithsiru/at/'
                                        name='Austria'
                                        countryCode='AT'
                                        disabled={enabledCountries && !enabledCountries.includes('AT')}
                                    />
                                    <SelectorLink
                                        to='/paywithsiru/ng/'
                                        name='Nigeria'
                                        countryCode='NG'
                                        disabled={enabledCountries && !enabledCountries.includes('NG')}
                                    />
                                </div>
                            </NumberedRow>
                        </StyledDiv>
                        <GatsbyImage
                            className='position-absolute w-100 h-100'
                            alt=''
                            image={bgImage}
                            role='presentation'
                            placeholder='blurred'
                            loading='lazy'
                        />
                    </div>
                </Container>
            </section>
        </SelectorStyles>
    )
}

const SelectorLink = ({ to, name, countryCode, active = false, disabled = false }) => (
    <div className='d-inline-block bg-light me-0 me-md-3 mb-2 shadow'>
        <Link
            to={to}
            className={`btn btn-outline-dark fs-6 rounded-0 spaced-light ps-4 pe-5 w-100 ${active && 'active'} ${
                disabled && 'disabled'
            }`}>
            <ReactCountryFlag
                className='ms-3 me-1'
                style={{ width: '1.1rem', height: '1.1rem', marginBottom: '3px' }}
                countryCode={countryCode}
                svg
                title={countryCode}
            />{' '}
            {name}
        </Link>
    </div>
)

const StyledDiv = styled.div`
    z-index: 1;
`

export default CountrySelector
